





























import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Calculator extends Vue {
  sendKey(key: string) {
    this.$emit('sendKey', key);
  }
}
