














































import { Vue, Component } from 'vue-property-decorator';
import CalculatorDemo from './calculator-demo.vue';
@Component({
  components: { CalculatorDemo }
})
export default class Data extends Vue {
  conditionList: any = [];

  addCondition() {
    this.conditionList.push({});
  }

  removeCondition(index: number) {
    this.conditionList.splice(index, 1);
  }

  calcList: any[] = [];
  activeIndex: number = -1;
  addCalc() {
    this.calcList.push([]);
    this.activeIndex = this.calcList.length - 1;
  }

  closeTab() {
    this.$emit('close');
  }
}
