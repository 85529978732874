


































































import { Vue, Component, PropSync } from 'vue-property-decorator';
import Calculator from './calculator.vue';
@Component({
  components: { Calculator }
})
export default class Data extends Vue {
  @PropSync('list') validateList!: any;
  @PropSync('active') activeIndex!: number;


  removeValidate(index: number) {
    this.validateList.splice(index, 1);
    this.activeIndex = -1;
  }

  sendKey(key: string) {
    let index = this.activeIndex;
    if (key === 'ac_remove') {
      this.validateList[index].pop();
    } else if (key === 'ac_init') {
      this.validateList[index].splice(0, this.validateList[index].length);
    } else if (key === 'ac_done') {
      this.activeIndex = -1;
    } else {
      this.validateList[index].push(key);
    }
  }
}
