<template>
  <div>
    <div class="card sheet-card">
      <div class="tools">
        <a class="sheet-btn"><span class="fa fa-pencil-square-o"></span>编辑</a>
        <a class="sheet-btn"><span class="fa fa-upload"></span>导入</a>
        <a class="sheet-btn"><span class="fa fa-play-circle-o"></span>预览</a>
        <a class="sheet-btn" @click="isCheck = !isCheck"><span class="fa fa-check-square-o"></span>校验</a>
        <a class="sheet-btn"><span class="fa fa-cog"></span>属性</a>
        <a class="sheet-btn"><span class="fa fa-level-up"></span>通过</a>
        <a class="sheet-btn"><span class="fa fa-level-down"></span>驳回</a>
        <a class="sheet-btn" @click="getSetting"><span class="fa fa-floppy-o"></span>保存</a>
        <a class="sheet-btn" @click="getSetting"><span class="fa fa-paper-plane"></span>提交</a>
        <a class="sheet-btn" @click="getSetting"><span class="fa fa-cloud-upload"></span>发布</a>
      </div>
      <div class="expand" :class="isCheck ? 'open' : 'close'">
        <Validate @close="activeKey = '0'" />
      </div>
      <div class="sheet-container">
        <div class="container">
          <div id="luckysheet"></div>
        </div>
        <div class="right-bar">
          <a-tabs v-model="activeKey" tab-position="right">
            <a-tab-pane key="1" tab="数据">
              <Data @close="activeKey = '0'" />
            </a-tab-pane>
            <a-tab-pane key="2" tab="规则">
              <Rule @close="activeKey = '0'" />
            </a-tab-pane>
            <a-tab-pane key="3" tab="校验">
              <Validate @close="activeKey = '0'" />
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { conf } from './conf';
import Data from './data.vue';
import Rule from './rule.vue';
import Validate from './validate.vue';
export default {
  components: { Data, Rule, Validate },
  data() {
    return {
      activeKey: '0',
      visible: false,
      isCheck: false
    };
  },
  mounted() {
    $(function () {
      luckysheet.create({
        container: 'luckysheet',
        lang: 'zh',
        showinfobar: false,
        showsheetbar: false,
        data: conf
      });
    });
  },
  watch: {
    activeKey(n) {
      console.log(n);
      this.visible = true;
    }
  },
  methods: {
    getSetting() {
      let a = luckysheet.getAllSheets();
      console.log(a);
    }
  }
};
</script>
<style lang="less">
.tools {
  background-color: #fafafa;
  padding: 10px 20px;
  display: flex;
  // justify-content: space-around;
  .sheet-btn {
    color: #666;
    padding: 0 15px;
    .fa {
      margin-right: 8px;
    }
    &:hover {
      color: #409eff;
    }
  }
}
.sheet-card {
  position: relative;
  .bread-crumb {
    margin-top: -15px;
  }
}
.expand {
  // position: absolute;
  // z-index: 1100;
  // width: 100%;
  background-color: #fff;
  height: 0;
  transition: height 0.3s;
  &.close {
    height: 0;
    overflow: hidden;
  }
  &.open {
    box-shadow: 0 10px 15px 2px #ccc;
    height: 500px;
    overflow: scroll;
  }
  .ant-collapse-borderless {
    background-color: #fff;
  }
  .title,
  .footer {
    display: none;
  }
}
.container {
  position: relative;
  width: 100%;
  height: calc(100vh - 196px);
  left: 0px;
  top: 0px;
  #luckysheet {
    margin: 0px;
    padding: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
}
.sheet-container {
  position: relative;
  .container {
    width: calc(100% - 30px);
  }
  .right-bar {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1010;

    .ant-tabs-nav {
      background-color: #fff;
    }
    .ant-tabs-tab {
      writing-mode: vertical-lr;
      padding: 20px 10px;
      height: auto;
    }
    .tab-pane-content {
      background-color: #fff;
      height: calc(100vh - 210px);
      border: 1px solid #ddd;
      margin-right: -23px;
      min-width: 500px;
      position: relative;
      .title {
        border-bottom: 1px solid #ddd;
        padding: 10px 20px;
        font-weight: 800;
        font-size: 15px;
      }
      .content {
        padding: 10px 20px;
        height: calc(100% - 100px);
        overflow: auto;
        .ant-collapse-borderless {
          background-color: #fff;
        }
      }
      .footer {
        position: absolute;
        background-color: #fff;
        bottom: 0;
        width: 100%;
        padding: 10px 20px;
        border-top: 1px solid #ddd;
      }
    }
  }
}
</style>
