























































































import { Vue, Component } from 'vue-property-decorator';
import CalculatorDemo from './calculator-demo.vue';
@Component({
  components: { CalculatorDemo }
})
export default class Data extends Vue {
  validateList1: any = [];
  validateList2: any = [];
  validateList3: any = [];

  addValidate1() {
    this.validateList1.push({});
  }

  removeValidate1(index: number) {
    this.validateList1.splice(index, 1);
  }

  addValidate2() {
    this.validateList2.push({});
  }

  removeValidate2(index: number) {
    this.validateList2.splice(index, 1);
  }

  addValidate3() {
    this.validateList3.push([]);
    this.activeIndex = this.validateList3.length - 1;
  }

  activeIndex: number = -1;

  closeTab() {
    this.$emit('close');
  }
}
