





















































import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Data extends Vue {
  closeTab() {
    this.$emit('close');
  }

  dataType: number | string = '';

  switch1: number = 0;
  switch2: number = 0;
}
